import { ITheme } from "../../model/theme/Theme";
import CapricornLogo from "./CapricornLogo"

const CapricornTheme: ITheme = {
    rootClassName: 'capricorn',
    logo: CapricornLogo,
    content: {
        header: {
            businessName: true,
            moduleName: true
        },
        settings: {
            businessDetails: true,
            businessLocations: true,
            betaFeatures: true
        },
        notifications: true,
        zohoChat: true
    }
};

export default CapricornTheme;
