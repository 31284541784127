import {Box} from '@material-ui/core';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {Link, useHistory, useLocation, useParams, matchPath} from 'react-router-dom';
import AppContext from '../../context/app/appContext';
import {MenuTypes} from '../../model/constants/Constants';
import {Settings} from '../../assets/svg/Settings';
import {Bell} from '../../assets/svg/Bell';
import {ITenant, ITenantInfo, LogoTypeEnum} from '../../model/Tenant';
import {IMenuOption} from '../../model/tenant/TenantModuleDetails';
import {IUser} from '../../model/user/User';
import {NameInitials} from '../../utils/formatter';
import {SMenuItem} from '../navigation/MenuItem';
import {ModuleBrowser} from '../navigation/ModuleBrowser';
import SVGIcon, {SVGName} from '../ui/SVGIcon';
import _, {isEmpty} from 'lodash';
import {IConnectedSupplier} from '../../model/supplier/ConnectedSupplier';
import {IUserMenu} from '../../services/MenuService';
import {HeaderDialogs} from '../menu/HeaderDialogs';
import {useFeatureFlags} from '../../hooks/useFeatureFlags';
import {Profile} from '../../assets/svg/Profile';
import {useTenantInfo} from '../../hooks/useTenantInfo';
import {
  AUTH_SELLING_AR_PAID_INVOICES_ROUTE,
  AUTH_SELLING_AR_PAYMENT_HISTORY_ROUTE,
  AUTH_SUPPLIER_ROUTE,
} from '../../routes/AuthenticatedRoutes';
import {ISupplierMarket} from '../../model/SupplierMarket';
import Skeleton from 'react-loading-skeleton';
import {UserTenantDropDown} from '../tenant/UserTenant';
import {useSignalR} from '../../hooks/useSignalR';
import Environment from '../../utils/environment';
import {NotificationContext, INotification} from '../../context/NotificationContext';
import environment from '../../utils/environment';
import {CapricornApplicationInfo} from '../../model/constants/ApplicationInfo';
import {usePSBLHook} from '../../hooks/useARHook';
import {isWebsiteId13} from '../../config';
import VisibleContent from '../ui/VisibleContent';

interface HeaderProps {
  afterUserSettingDialogClose?: () => void;
}

export const Header: React.FC<HeaderProps> = ({afterUserSettingDialogClose}) => {
  // Context
  const appContext = useContext(AppContext);

  // Hooks
  const history = useHistory();
  let {customerId, transactionId, supplierId} = useParams<{
    supplierId?: string;
    customerId?: string;
    transactionId?: string;
  }>();
  const {pathname} = useLocation();
  const {isInBuyerContext} = useTenantInfo();
  const {isPaymentOptionAvailable} = usePSBLHook();

  // We only use the header in authenticated app, so we will have a user
  let user = appContext.user as IUser;
  let tenant = appContext.tenant as ITenant;
  const supplier = appContext.marketplaceSupplier;
  const tenantInfo = appContext.tenantInfo as ITenantInfo;
  const suppliers = appContext.suppliers as IConnectedSupplier[];
  const menu = appContext.menu as IUserMenu;
  const mpSupplier = appContext.marketplaceSupplier as ISupplierMarket;

  // Feature Flags
  const {...featureFlagsTenantOwned} = useFeatureFlags().tenantOwned();
  const featureFlagsSupplierDriven = useFeatureFlags().supplierDriven();

  // State
  const [userAnchorEl, setUserAnchorEl] = useState<null | HTMLElement>(null);
  const [settingsAnchorEl, setSettingsAnchorEl] = useState<null | HTMLElement>(null);
  const [notificationsAnchorEl, setNotificationsAnchorEl] = useState<null | HTMLElement>(null);
  const [isLoadingMarketplace, setIsLoadingMarketplace] = useState<boolean>(
    pathname.includes(AUTH_SUPPLIER_ROUTE) && parseInt(supplierId || '0') !== mpSupplier?.SupplierID,
  );

  const sm = _.cloneDeep(Object.values(appContext.menu || {}).find(v => v.IsSelected));

  // useEffects
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      setIsLoadingMarketplace(
        pathname.includes(AUTH_SUPPLIER_ROUTE) && parseInt(supplierId || '0') !== mpSupplier?.SupplierID,
      );
    }

    return () => {
      mounted = false;
    };
  }, [mpSupplier?.SupplierID]);

  const node = useRef(null);

  let selectedSupplier = null;
  if (!appContext.marketplaceSupplier && supplierId) {
    selectedSupplier = appContext.suppliers?.find(ss => ss.SupplierID === (supplierId && parseInt(supplierId)));
  } else {
    selectedSupplier = appContext.suppliers?.find(ss => ss.SupplierID === appContext.marketplaceSupplier?.SupplierID);
  }
  const sname = selectedSupplier;
  const supplierIcon = selectedSupplier?.Logos?.find(sI => sI.LogoTypeID.toString() === LogoTypeEnum.Icon);
  const supplierBanner: {URI: string} | undefined = selectedSupplier?.Logos?.find(
    sb => sb.LogoTypeID.toString() === LogoTypeEnum.Banner,
  );

  const HeaderTitle = (props: {sm: any}) => {
    const {sm} = props;

    return (
      <>
        <div className="mr-1">
          <SVGIcon name={sm?.selectedMenu?.Src} />
        </div>
        <VisibleContent keyPath="header.moduleName">
          <>
            <p className={`ml-2 font-poppins text-2xl font-light text-black-800`}>{sm && sm.Title}</p>
            {sm && sm.IsSelected ? (
              <p className={'mx-2'} style={{fontSize: '13px', paddingTop: '2px', color: '#333333'}}>
                &#9679;
              </p>
            ) : (
              ''
            )}
          </>
        </VisibleContent>
        <p className={`font-poppins text-2xl font-light text-black-800`}>{sm?.selectedMenu?.Name}</p>
      </>
    );
  };

  return (
    <Box className={`${`relative col-start-1 col-end-2 row-start-1 row-end-2 flex justify-center bg-white p-0`}`}>
      <HeaderDialogs
        userAnchorEl={userAnchorEl}
        settingsAnchorEl={settingsAnchorEl}
        notificationsAnchorEl={notificationsAnchorEl}
        setUserAnchorEl={setUserAnchorEl}
        setSettingsAnchorEl={setSettingsAnchorEl}
        setNotificationsAnchorEl={setNotificationsAnchorEl}
        afterUserSettingDialogClose={afterUserSettingDialogClose}
      />
      <ModuleBrowser
        user={user}
        suppliers={suppliers}
        tenantInfo={tenantInfo}
        menu={menu}
        supplierId={supplierId}
        customerId={customerId}
        transactionId={transactionId}
        pathname={pathname}
        tenant={tenant}
        modules={appContext.modules}
      />

      <div className="w-full">
        <div className="flex items-center justify-between">
          <div ref={node}>
            <h1 className={`justify-left mx-5 mt-3 flex items-center text-xl text-white`}>
              {sm?.Title === MenuTypes.Buying && !sm?.selectedMenu ? (
                <SupplierMarketplaceIconName
                  svgIconName={sname?.IsLendingProvider && isWebsiteId13 ? SVGName.TradeFinance : undefined}
                  isLoading={isLoadingMarketplace}
                  supplierIconUrl={supplierIcon?.URI}
                  supplierName={environment.WEBSITE_ID == CapricornApplicationInfo.WebsiteId ? '' : sname?.SupplierName}
                />
              ) : (
                <HeaderTitle sm={sm} />
              )}
            </h1>
          </div>
          <div id="MarketingBanner">
            {sm?.Title === MenuTypes.Buying &&
              supplierBanner?.URI &&
              !isLoadingMarketplace &&
              sm?.selectedMenu?.Name !== 'Accounts Payable' && (
                <img className="rounded-5 mt-1 h-[50px] w-[468px] object-fill" alt="" src={supplierBanner?.URI} />
              )}
          </div>
          <div className={`relative z-20 flex h-max items-center text-xl`}>
            <UserTenantDropDown userName={`${user?.FirstName} ${user?.LastName}`} tenantName={tenant?.Name || ''} />

            <button
              data-autoid="btnProfile"
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => setUserAnchorEl(event.currentTarget)}
              className={`mr-3 flex cursor-pointer items-center focus:outline-none`}
            >
              <Profile />
            </button>
            <button
              data-autoid="btnSettings"
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => setSettingsAnchorEl(event.currentTarget)}
              className={`mr-3 cursor-pointer focus:outline-none`}
            >
              <Settings />
            </button>

            <VisibleContent keyPath="notifications">
              <NotificationsButton
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => setNotificationsAnchorEl(event.currentTarget)}
                className="mr-4 cursor-pointer focus:outline-none"
              />
            </VisibleContent>
          </div>
        </div>
        <Box
          height={'40px'}
          alignItems="center"
          className={`flex bg-primary/20 text-lg text-white  ${
            !supplierId ? 'pl-[110px]' : 'px-5'
          } border-b-[1px solid #D3E5EF] border-t-[1px solid #D3E5EF] absolute bottom-0 left-0 z-10 w-full `}
        >
          {isLoadingMarketplace
            ? [0, 1, 2, 3].map(k => <Skeleton className="mr-4 bg-[#e4e7e7]" key={k} width={160} height={20} />)
            : sm?.SubMenuItems?.map((item: IMenuOption, id: number) => {
                const hasFlags = !isEmpty(item.FeatureFlags);
                const isSupplierDrivenFeatureFlagsEnable = Object.entries(item.FeatureFlags || {})?.every(
                  ([key, value]) => String(featureFlagsSupplierDriven[key]) === String(value),
                );
                const isTenantOwnedFeatureFlagsEnable = Object.entries(item.FeatureFlags || {})?.every(
                  ([key, value]) => String(featureFlagsTenantOwned[key]) === String(value),
                );

                // Checking Supplier Driven Feature Flags
                if (isInBuyerContext && hasFlags && !isSupplierDrivenFeatureFlagsEnable) {
                  return null;
                }

                // Checking Tenant Owned Feature Flags
                if (!isInBuyerContext && hasFlags && !isTenantOwnedFeatureFlagsEnable) {
                  return null;
                }

                let path = '/';

                path = item.Route?.replace(':supplierId', (supplier?.SupplierID || 0).toString()) || '/';

                if (item?.queryString && !item?.Route) {
                  path = `${history.location.pathname}`;
                }

                // Hiding Prepayment Menu If Supplier Has Not Setup Payments Service
                if (
                  isInBuyerContext &&
                  !isPaymentOptionAvailable &&
                  path === `/supplier/${supplier?.SupplierID}/prepayment`
                ) {
                  return null;
                }

                // Append Customer Id On Payment History Menu When We Are In The Customer Focus View
                if (
                  /\/selling\/collect\/[0-9]+$/gi.test(pathname) &&
                  customerId &&
                  (path === AUTH_SELLING_AR_PAYMENT_HISTORY_ROUTE || path === AUTH_SELLING_AR_PAID_INVOICES_ROUTE)
                ) {
                  path = `${path}/${customerId}`;
                }

                const match = matchPath(pathname, {path: path});
                return (
                  <Link key={id} to={{pathname: path, search: item?.queryString}} data-autoid={item.dataAutoId}>
                    <SMenuItem
                      name={item.AttachSupplierName ? `${item.Name} ${sname?.SupplierName}` : item.Name}
                      iconSrc={item.Src}
                      isActive={item.Route ? Boolean(match) : false}
                    />
                  </Link>
                );
              })}
        </Box>
      </div>
    </Box>
  );
};

interface INotificationsButton {
  dataAutoid?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  className: string;
}

export const NotificationsButton = (props: INotificationsButton) => {
  const {dataAutoid, onClick, className} = props;

  const connection = useSignalR(`${Environment.API_URL}api/Spenda/Alerts/Notifications`);
  const {addNotification, notifications} = useContext(NotificationContext);

  const newNotificationsCount = notifications.filter(n => !n.isAcknowledged).length;
  const newNotificationsString =
    newNotificationsCount > 0 ? (newNotificationsCount > 99 ? '99+' : newNotificationsCount.toString()) : '';

  useEffect(() => {
    let cancel = false;

    if (connection) {
      connection.on('Notification', (n: INotification) => {
        if (cancel) return;
        addNotification(n);
      });
    }

    return () => {
      cancel = true;
    };
  }, [connection, addNotification]);

  return (
    <button data-autoid={dataAutoid || 'btnNotifications'} onClick={onClick} className={className}>
      <Bell />
      <NotificationBadge count={newNotificationsString} />
    </button>
  );
};

// Small notification counter badge component using tailwind styling
const NotificationBadge = (props: {count?: string}) => {
  const {count} = props;
  return count ? (
    <div className={`absolute right-1 top-1 h-6 w-6 rounded-full bg-red-600 p-1 text-xs text-white`}>{count}</div>
  ) : null;
};

const SupplierMarketplaceIconName = (props: {
  isLoading: boolean;
  supplierIconUrl?: string;
  supplierName?: string;
  svgIconName?: SVGName;
}) => {
  const {isLoading, supplierIconUrl, supplierName, svgIconName} = props;

  if (isLoading) {
    return (
      <div style={{marginTop: '-10px'}}>
        <Skeleton circle={true} width={40} height={40} className="mr-1" />
        <Skeleton width={150} height={40} />;
      </div>
    );
  }

  return (
    <>
      {svgIconName ? (
        <SVGIcon name={svgIconName} width="45" height="45"></SVGIcon>
      ) : supplierIconUrl ? (
        <div className="rounded-[5px]">
          <img className="h-10 w-10 object-fill" alt="" src={supplierIconUrl} />
        </div>
      ) : (
        <div className="rounded-[5px]">
          <p className="relative h-12 w-12 justify-center rounded-full bg-[#399F76] p-2 text-center text-white">
            {NameInitials(supplierName)}
          </p>
        </div>
      )}
      <span
        data-autoid="txtSupplierName"
        className={`ml-1 ${supplierIconUrl ? '' : 'mt-1'} font-poppins text-3xl font-light text-black-800`}
      >
        {supplierName}
      </span>
    </>
  );
};
